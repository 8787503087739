// background-color: var(--primary-bg);

.app {
  font-family: var(--font-base);
}

.app__container {
  min-height: 100vh;
  // width: 100%;
  display: flex;

  padding: 2rem 3rem;

  width: min(2000px, 100%);

  @media screen and (min-width: 1120px) {
    padding: 4rem 14rem 2rem;
  }
  @media screen and (min-width: 2000px) {
    padding: 7.2rem 18rem;
  }
  @media screen and (min-height: 1000px) {
    min-height: 50vh !important;
  }
  transition: all 0.3s ease-in-out;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-text {
  color: var(--head-color);
  font-size: 1rem;
  line-height: 1.5;
}

.title-container {
  color: var(--black-color);
  font-size: 1.5rem;
  font-weight: 700;
}

.title-text {
  color: var(--black-color);
  font-size: 1.125rem;
  font-weight: 500;
}

.btn {
  padding: 0.7rem 1.5rem;

  font-family: var(--font-base);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--secondary-bg);
  // color: var(--gray-primary-color);

  border: 0;
  border-radius: 1px;
}
