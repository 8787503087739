.app__about {
  flex-direction: column;
  background-color: var(--third-bg);

  gap: 15px;

  overflow: hidden;
}

.app__about-me {
  width: 100%;

  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    gap: 25px;
  }

  .about-me-img {
    display: flex;
    width: 100%;
    flex: 1;

    img {
      margin: auto;
      width: 54%;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .about-me-infos {
    flex: 1;

    display: flex;
    flex-direction: column;

    gap: 1.125rem;

    .quote {
      margin: auto;
    }
    q {
      // border-left: 10px solid #ccc;
      margin: auto;
      padding: 0.5em 10px;
      quotes: "\201C""\201D""\2018""\2019";

      &::before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
      }
      &::after {
        color: #ccc;
        content: close-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-left: 0.25em;
        vertical-align: -0.7em;
      }
      & p {
        display: inline;
      }
    }
    a {
      text-decoration: none;
    }
    .about-btn {
      display: flex;
      width: -moz-fit-content;
      width: fit-content;

      // place-self: flex-end;

      button:first-child {
        cursor: pointer;
        background: var(--red-color);
      }
      // button:last-child {
      //   background: var(--black-color);
      //   color: white;

      //   width: calc(56px + (25px * 2));

      //   padding: 0;
      //   margin-left: 48px;

      //   font-size: 1.2rem;
      //   font-weight: 500;
      // }

      @media screen and (max-width: 640px) {
        place-self: center;

        button {
          margin-top: 1rem;
        }

        // justify-content: space-evenly;
      }
    }
  }
}

.app__about-skills {
  //   background: purple;

  flex: 1;

  width: 100%;
  display: flex;

  .about-skills-vl {
    // position: relative;
    transform: rotateZ(180deg);
    writing-mode: vertical-lr;
    margin: auto 0;

    // background: #000;
  }

  .about-skills {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .about-skills-wrapper {
      background-color: transparent;

      width: 190px;
      display: flex;
      flex-direction: column;
      margin: 2rem;

      // gap: 4px;
      &:first-child {
        img {
          // background-color: #734f22;
          background-color: rgba(18, 18, 88, 0.922);
          padding-bottom: 7.5px;
        }
      }
      &:nth-child(2) {
        img {
          background-color: rgba(18, 18, 88, 0.155);
        }
      }
      img {
        width: 100%;
        height: 100%;
        // height: 170px;
        object-fit: cover;
        background-size: cover;
        border-radius: 15px;
        margin-bottom: 10px;
      }
    }
  }
}
