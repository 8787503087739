.app__header {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  position: fixed;
  z-index: 100;

  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;

  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.app__header__logo {
  .logo {
    img {
      width: 2.4rem;
      height: 2.4rem;

      @media screen and (min-width: 768px) {
        width: 2.7rem;
        height: 2.7rem;
      }
    }
  }
}

.app__header__nav {
  flex: 1;

  ul {
    flex-direction: row;
    column-gap: 1.75rem;

    list-style: none;
    text-transform: uppercase;

    li {
      a {
        text-decoration: none;
        background-color: #4158d0;
        background-image: linear-gradient(12deg, #c850c0 0%, #ffa470 77%);

        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        color: rgb(244, 232, 252); // fallback
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: 1px;
        position: relative;

        &:hover {
          color: var(--red-secondary-color);
          cursor: pointer;

          &::after {
            width: 100%;
          }
        }
        &::after {
          content: "";
          position: absolute;
          background-color: var(--red-secondary-color);
          height: 3px;
          width: 0;
          left: 0;
          bottom: -7px;
          transition: all 0.3s ease-out;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__header-menu {
  .svg-setting {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
  .app__header-menu-icons {
    width: 2.7rem;
    height: 2.7rem;

    position: relative;
    z-index: 100;

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      fill: url(#Gradient1);
    }

    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  .app__header-menu-links {
    position: fixed;
    right: 0;
    top: 50vh;
    transform: translateY(-50%);

    min-height: max-content;

    display: flex;
    flex-direction: column;

    border-radius: 20px 0px 0 20px;
    box-shadow: 10px 0px 30px 20px rgba(168, 168, 168, 0.092);

    background: linear-gradient(
      to bottom,
      hsla(263, 77%, 17%, 0.924) 1%,
      hsl(240, 47%, 21%) 90%
    );
    z-index: 199;
    li {
      display: flex;
      justify-content: center;

      list-style: none;
      width: 100%;

      &:first-child {
        border-top-left-radius: 20px;
      }
      &:last-child {
        border-bottom-left-radius: 20px;
        &:hover {
          background-color: rgb(0, 223, 97);
        }
      }
      &:hover {
        a {
          color: white;
        }

        background-color: var(--red-secondary-color);
        cursor: pointer;
      }
      svg {
        width: 25px;
        height: 25px;
      }
      a {
        padding: 1.6rem 0.8rem 1.6rem 1.5rem;
        width: 100%;

        gap: 10px;

        display: flex;
        flex-direction: row;
        align-items: center;

        text-transform: uppercase;
        text-decoration: none;
        color: var(--gray-secondary-color);

        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 1px;
      }

      transition: all 0.2s ease-out;
    }

    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}
