.scrollToTop {
  background-color: rgb(18, 18, 88);
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  svg {
    color: white;
    font-size: 1.5rem;
  }
}
