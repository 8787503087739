.app__footer {
  padding: 0.5rem 0;
  text-align: center;
  background-color: var(--black-color);
  color: white;

  font-size: small;
  font-weight: 400;

  a {
    text-decoration: none;
    color: wheat;
  }
}
