.app__social {
  height: 100%;

  display: flex;
  gap: 1.25rem;
  flex-direction: row;

  padding-bottom: 3.5rem;

  // color: var(--black-color);

  // background: rgb(89, 60, 184);

  transition: all 0.3s ease-in-out;

  a {
    cursor: pointer;
    text-decoration: none;
    transform: scale(1);

    svg {
      color: aliceblue;
      text-shadow: 0 0 20 hsl(0, 0%, 0%);
    }

    &:hover {
      svg {
        transform: scale(1.5);
        text-shadow: 0 0 20 hsl(0, 0%, 69%);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
  }

  .fb {
    svg:hover {
      color: hsl(214, 89%, 52%);
    }
  }
  .git {
    svg:hover {
      color: hsl(0, 69%, 50%);
    }
  }
  .whtsp {
    svg:hover {
      color: hsl(142 70% 49%);
    }
  }
  .twt {
    svg:hover {
      color: hsl(203, 89%, 53%);
    }
  }
}
