@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&display=swap");

:root {
  /* font */
  --font-base: "Montserrat", sans-serif;
  /* bg */
  --primary-bg: #d3ddf5;
  --secondary-bg: #fff;
  --third-bg: #f8f9fd;
  /* text */
  --red-color: #ff023f;
  --gray-primary-color: #565b67;
  --gray-secondary-color: #6b7688;
  --wb-color: #f4f7ff;

  /* --black-color: #373b4c; */
  --black-color: #3d4454;

  --head-color: #3d4454;
  --title-color: #3e4555;
  /* header */
  --red-secondary-color: #d11943;
  /* --header-hover-color: #ff87ae;  old */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* suuuuup */
html {
  font-size: 17px;
  min-height: calc(var(--vh, 1vh) * 100);
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width: 900px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 13px; /*13px*/
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #121258;
  background: linear-gradient(349deg, #072142, #8c2b7a 52%, #ff4d5a);
}

/* ::-webkit-scrollbar-thumb:hover {
   background: linear-gradient(#e66465, #9198e5); 
} */