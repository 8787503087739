.app__skills {
  min-height: 80vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  background-color: var(--secondary-bg);
}

.app__skills-text {
  text-align: center;
  width: 70%;

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  div:last-child {
    margin-top: 15px;
  }
}

.swiper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  padding: 0 2px;

  .swiper-slide {
    height: auto;

    background-color: rgb(244, 247, 255);

    position: relative;
    top: 0;
    outline: 2px #dce1ed solid;

    transition: all 0.3s ease-out;

    &:hover {
      box-shadow: 0 10px 26px #dadde5;
      outline: 0;

      cursor: grab;

      top: -10px;
      .title-slide {
        color: var(--clr-hover);
      }
    }

    .swiper-slide-content {
      width: 100%;
      height: 100%;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      text-align: center;
    }

    .title-slide {
      width: 100%;
      svg {
        width: 3rem;
        height: 3rem;
        margin-bottom: 2px;
      }

      text-decoration: none;
      color: var(--gray-secondary-color);
      font-size: 0.9rem;
      font-weight: 700;
      letter-spacing: 1px;
      height: 50%;
    }
    .p-text {
      width: 100%;
      height: 40%;
    }
  }
  .swiper-pagination {
    position: relative;

    .swiper-pagination-bullet {
      width: 13px;
      height: 13px;

      opacity: 1;

      background-color: transparent;

      border: 2px #252a34 solid;
    }
    .swiper-pagination-bullet-active {
      background-color: #252a34;
    }
  }
}
