.app__contact {
  flex-direction: column;
  min-height: 70vh !important;

  background-color: var(--secondary-bg);
  justify-content: center;
  align-items: center;
  gap: 2rem;
  // background: rgb(226, 217, 47);
  .title-container {
    padding-top: 2rem;
  }
}
.app__contact-info {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    .app__contact-arrow {
      writing-mode: vertical-lr;
    }
  }
}

.app__contact-infos {
  // background: red;

  width: 100%;
  // height: 100% ;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-items: start;
  }

  .infos-p {
    strong {
      background: linear-gradient(to right, #30a3d0 0%, #d118c7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .infos-te {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
      margin-bottom: -6px;
    }

    .infos-tel {
      text-decoration: none;
      padding: 0.7rem 0 0 0.7rem;

      color: black;

      &:hover {
        color: #25d366;
      }
    }
    .infos-email {
      color: black;
      text-decoration: none;
      padding: 0.7rem 0 0 0.7rem;

      &:hover {
        color: #1da1f2;
      }
    }
  }
}

.app__contact-arrow {
  // background: green;

  margin: 0 auto;
  justify-self: center;
  align-self: center;

  padding: 2.3rem;
  font-size: 2rem;
}

.app__contact-mailing {
  width: 100%;

  .mailing-forms {
    align-self: center;
    width: 100%;

    margin: auto;
    padding: 3rem 2rem;

    box-shadow: 0 0 20px 0 #bcbcbc78;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #f8f9fd;
      box-shadow: 0 0 20px 0px hsla(231, 48%, 64%, 0.49);
    }

    @media screen and (max-width: 900px) {
      width: 50%;
      padding: 3rem 2rem;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    form {
      margin: auto;

      padding-top: 20px;
      margin-top: -20px;

      display: flex;
      flex-direction: column;

      gap: 35px;
      outline: 0;
      overflow: hidden;

      .inp-name,
      .inp-email,
      .textarea {
        position: relative;
      }

      input,
      textarea {
        background-color: transparent;
        // font-size: 1.4rem;
      }

      input {
        width: 100%;
        height: 100%;
        color: #595f6e;
        padding-top: 20px;
        border: 0;

        &:focus + label {
          span {
            transform: translateY(-32px);
          }
        }
        &:valid + label {
          span {
            transform: translateY(-32px);
            color: #50c878;
          }
        }
        &:focus + label::after,
        &:valid + label::after {
          transform: translateX(0%);
          background-color: #85e84221;
        }
        &:not(:valid) {
          color: var(--red-secondary-color);
        }

        // &[type="email"]:not(:placeholder-shown) + label {
        //   span {
        //     transform: translateY(-52px);
        //   }
        // }
      }

      label {
        position: absolute;
        bottom: 0px;
        left: 0%;
        width: 100%;
        height: 100%;

        font-size: 1rem;
        font-weight: 400;

        pointer-events: none;

        border-bottom: 1px solid #595f6e;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -3px;
          height: 100%;
          width: 100%;
          border-bottom: 3px solid #595f6e;
          transform: translateX(-100%);
          transition: 0.4s all ease;
        }

        span {
          position: absolute;
          bottom: 5px;
          left: 0;
          transition: 0.3s all ease;
        }
      }

      textarea {
        border: 0;
        outline: 0;
        width: 100%;
        resize: none;
        &:focus + label {
          span {
            transform: translateY(-670%);

            @media screen and(max-width:900px) {
              transform: translateY(-137px);
            }
          }
        }
        &:valid + label {
          span {
            color: #50c878;
            transform: translateY(-670%);

            @media screen and(max-width:900px) {
              transform: translateY(-137px);
            }
          }
        }

        &:focus + label::after,
        &:valid + label::after {
          transform: translateX(0%);
          background-color: #85e84221;
        }
      }

      .contact-btn {
        align-self: end;
        cursor: pointer;
        background: var(--black-color);
      }
    }
  }
}

.ok-send {
  width: 97%;
  text-align: center;
  transition: all cubic-bezier(0.895, 0.03, 0.685, 0.22);
  b {
    color: green;
  }
}
