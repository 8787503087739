//

.app__hero {
  width: 100%;

  background-image: linear-gradient(
    50deg,
    hsl(304, 52%, 55%) 0%,
    hsl(240, 86%, 25%) 100%
  );
  background-color: #7538eee0;

  // background-image: url("../../assets/images/animated-loop-1536x864.jpg");
  // background-image: url("../../assets/images/optimized1-blurred-pop-abstract-background-with-vivid-primary-colors-min.jpg");

  // background-image: url("../../assets/images/Optimized-blurred-pop-abstract-background-with-vivid-primary-colors.jpg");

  // background-color: #7538eee0;

  // background: rgb(2, 0, 36);
  // background: linear-gradient(
  //   0deg,
  //   rgba(2, 0, 36, 1) 0%,
  //   rgba(9, 9, 121, 1) 100%,
  //   rgb(102, 0, 255) 35%
  // );

  // radial:

  // background-image: linear-gradient(
  //   243deg,
  //   hsl(304, 52%, 55%) 10%,
  //   hsl(240, 86%, 25%) 100%
  // );

  // background-repeat: no-repeat;
  // background-blend-mode: multiply;
  // object-fit: cover;
  // background-size: cover;
  // background-position: top;

  // background: linear-gradient(45deg, #5054bb, transparent);
  // background-color: var(--primary-bg);
  // background-color: rgb(95, 41, 202);

  padding: 4rem 3rem 0;

  min-height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: row;

  @media screen and (min-width: 760px) {
    padding: 6rem 8.5rem 0;
  }

  & > .app__social {
    place-self: flex-end;
  }
  position: relative;
}
.app__hero-bg {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
  // z-index: -1;
  // background-color: #7538eee0;
  // background-image: linear-gradient(
  //   243deg,
  //   hsl(304, 52%, 55%) 10%,
  //   hsl(240, 86%, 25%) 100%
  // );

  // img {
  //   -o-object-fit: cover;
  //   object-fit: cover;
  //   -o-object-position: 60% 0%;
  //   object-position: 60% 0%;
  //   transform: rotate(180deg);
  // }
}

.app__hero-hello {
  flex: 1;
  display: flex;
  place-self: center center;
  color: whitesmoke;

  .app__hero-infos {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    gap: 1rem;
    &:not(:nth-of-type(span)) {
      text-shadow: 0px 0px 20px rgba(21, 96, 161, 0.692);
    }
    position: relative; //social media
    @media screen and (max-width: 950px) {
      text-align: center;
    }
    h4 {
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 1.8rem;
    }
    h1 {
      font-size: 2.5rem;
      font-weight: 500;

      span {
        font-weight: 500;
        color: transparent;
        background: linear-gradient(225deg, #d134c9 0%, #30a3d0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-shadow: -0px 5px 20px rgba(0, 0, 0, 0.214);

        transition: all 0.4s ease-in-out;
        @media screen and (max-width: 950px) {
          // font-weight: 300;
        }

        &:hover {
          text-shadow: 1px 1px 20px rgb(213 213 213 / 48%);
        }
      }
    }
    p {
      color: whitesmoke;
      width: 100%;

      strong {
        background: linear-gradient(
          170deg,
          #ff78f8 0%,
          #9a82f0 50%,
          #c3b3ff 100%
        );
        // background: linear-gradient(
        //   to right,
        //   #8398ff 0%,
        //   #ebc8f4 50%,
        //   #8398ff 100%
        // );

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 700px) {
        font-size: 1rem;
      }
    }
  }

  .app__hero-img {
    flex: 0.5;

    padding-bottom: 1rem;

    img {
      width: 55%;
      object-fit: cover;
      max-width: 200px;
      @media screen and (max-width: 950px) {
        width: 40%;
      }
    }

    flex-direction: row;
    flex-wrap: nowrap;
    // order: 1;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;
  }

  .app__social {
    display: none;

    padding: 0;
    // position: unset;
    @media screen and (max-width: 950px) {
      order: -1;
      padding-top: 12%;

      display: flex;
      position: relative;
      gap: 7px;

      div {
        padding-right: 10px;
        float: left;
      }

      transition: width 0.4s ease-in-out;
    }
  }
}

.app__social {
  svg {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 950px) {
    display: none;
    margin: auto;
  }
}
.app__hero-scroll {
  //   background: gray;

  writing-mode: vertical-rl;

  padding: 0 0 3.5rem 0;

  font-size: 0.6rem;
  font-weight: 700;
  text-transform: uppercase;
  color: hsl(0, 0%, 100%);

  text-align: center;
  place-self: flex-end;

  span {
    place-self: center center;
    margin: auto;
    margin-left: 3px;

    font-size: 1rem;
    font-weight: 500;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
}
