.app__work {
  flex-direction: column;
  gap: 40px;
  background-color: var(--third-bg);

  @media screen and (max-width: 910px) {
    opacity: 1 !important;
    transform: translateX(0px) translateZ(0px) !important;
  }
  transition: all 0.3 cubic-bezier(0.6, 0.01, 0.05, 0.9);
}

.app__work-text {
  margin-top: 2rem;

  flex-direction: column;
  gap: 2rem;
  text-align: center;

  @media screen and (max-width: 910px) {
    opacity: 1 !important;
    transform: translateX(0px) translateZ(0px) !important;
  }
}

.app__work-filter {
  flex-direction: row;
  gap: 1.5rem;

  flex-wrap: wrap;

  position: relative;

  .item {
    padding: 0.3rem 0.5rem;

    letter-spacing: 1.1px;
    cursor: pointer;

    position: relative;
    z-index: 5;

    @media screen and (max-width: 910px) {
      opacity: 1 !important;
      transform: translateX(0px) translateZ(0px) !important;
    }
  }

  .indicator {
    position: absolute;
    padding-top: 9px;

    color: var(--red-secondary-color);
    font-size: x-large;
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-align: center;

    visibility: hidden;
    z-index: 1;

    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 910px) {
      opacity: 1 !important;
      transform: translateX(0px) translateZ(0px) !important;
    }
  }

  &:hover {
    .indicator {
      opacity: 1;
      visibility: visible;
    }
  }
  @media screen and (max-width: 910px) {
    opacity: 1 !important;
    transform: translateX(0px) translateZ(0px) !important;
  }

  .item-active {
    flex-direction: column;

    &::after {
      content: "...";
      position: absolute;
      bottom: -11px;

      color: var(--red-secondary-color);
      font-size: x-large;
      font-weight: 600;
      letter-spacing: 0.2rem;

      transition: all 0.2s ease-in-out;
    }
    @media screen and (max-width: 910px) {
      opacity: 1 !important;
      transform: translateX(0px) translateZ(0px) !important;
    }
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    .item-active::after {
      opacity: 0;
    }
  }
}

.app__work-works {
  flex: 1;
  width: 100%;

  gap: 7px;

  text-align: center;
  flex-wrap: wrap;

  .wrapper-work {
    border: 1px solid #d888d440;

    max-width: 400px;
    flex-direction: column;
    border-radius: 0.5rem;
    gap: 7px;
    padding: 1rem 1.5rem;

    @media screen and (max-width: 910px) {
      opacity: 1 !important;
      transform: translateX(0px) translateZ(0px) !important;
    }
    background: transparent;

    &:hover {
      border: none;
      background: linear-gradient(to right, #30a3d0 0%, #d134c9 100%);
      transform: scale(1.07) !important;

      .title-text,
      .p-text {
        text-shadow: -7px 5px 20px rgba(0, 0, 0, 0.48);
        color: white;
      }
      .work-type {
        background: linear-gradient(#ffffff2d, #ffffff2d);
        box-shadow: none;
        // box-shadow: inset 0px 8px 9px 0prgba(255, 255, 255, 0.626)8a;
      }
    }

    cursor: pointer;
    // transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transition: all 0.3s ease-in-out;
    .work-img {
      display: flex;
      gap: 0;
      flex-direction: column;
      justify-content: center;
      img {
        // height: 100%;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
      }
    }
    .work-type {
      font-weight: 400;
      font-size: smaller;

      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;
      color: #ffffff;
      background: linear-gradient(to right, #30a3d0 0%, #d134c9 100%);
      padding: 1px 0;
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: inset 0px 8px 9px 0px #ffffff8a;
    }
    .work-img-zero {
      @extend .work-img;
    }
    .work-content {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      a {
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 910px) {
    opacity: 1 !important;
    transform: translateX(0px) translateZ(0px) !important;
  }
}
